import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import EventListItem from "../components/EventListItem"

import Layout from "../components/layout"

const EventsPage = () => {
  const data = useStaticQuery(graphql`
    query AllEventsQuery {
      allAirtable(filter: {table: {eq: "Programming"}, data: {Hidden: {ne: true}, Start: {ne: null}, Status: {nin: ["Backlog", "Planning"]}}}, sort: {order: DESC, fields: data___Start}) {
        edges {
          node {
            data {
              Id
              Name
              Start
              End
              Description
              Alternate_CTA_Link
              Alternate_CTA_Label
            }
          }
        }
      }
    }
  `)
  const upcoming = data.allAirtable.edges.filter(edge => edge.node && Date.now() <= new Date(edge.node.data.End)).sort((a, b) => a.node.data.Start < b.node.data.Start ? -1 : 1)
  const past = data.allAirtable.edges.filter(edge => edge.node && Date.now() > new Date(edge.node.data.End))

  return (
    <Layout align="start">
      <section id="intro" style={{ maxWidth: 600, margin: '2.5rem 2.5rem 0 2.5rem' }}>
        <h1>Member Sessions</h1>
        <p>This is Embarc Collective's calendar of member-only sessions, which are designed to support teams across each business function. Our mindset: quality over quantity, so expect intimate sessions led by local and national experts. RSVP to any sessions that are relevant and value-add to your role.</p>
      </section>
      {
        upcoming.map((edge, i) => <EventListItem key={edge.node.data.Id} event={edge.node.data} promoted={i === 0} />)}
      <div id="past-events" role="divider" style={{ width: '100%', marginTop: '2rem' }}>Past events<hr /></div>
      {
        past.map(edge => <EventListItem key={edge.node.data.Id} event={edge.node.data} />)}
    </Layout>
  )
}

export default EventsPage
