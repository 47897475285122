import React from "react"
import Moment from "react-moment"
import Button from "./Button"
import ReactMarkdown from "react-markdown"

/*
marginTop: promoted ? '2.5rem' : 'none',
border: promoted ? '4px solid #f2617a' : 'none'
*/

const EventListItem = ({ event, promoted }) => {
  const past = Date.now() > Date.parse(event.End)
  const hasAlternateCTA = event.Alternate_CTA_Label && event.Alternate_CTA_Link
  let external = true
  let url = "https://www.notion.so/ecmembers/09c563c0479f4930b357bbf6fb28498f?v=0420478fc5d24238b17f654dea8409c6"
  let label = "See details"
  if (hasAlternateCTA) {
    url = event.Alternate_CTA_Link
    label = event.Alternate_CTA_Label
  }
  else if (!past) {
    url = `/events/${event.Id}`
    label = "RSVP"
    external = false
  }


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      padding: '2.5rem',
      marginTop: promoted ? '2.5rem' : 'none',
    }} key={event.Id}>
      <div role="content" style={{ minWidth: 350, maxWidth: 600, }}>
        <div>
          {
            past ?
              <Moment format="MMM DD, YYYY">{event.Start}</Moment> :
              <div>
                <Moment format="dddd MMMM DD">{event.Start}</Moment>, <Moment format="h:mm a">{event.Start}</Moment>–<Moment format="h:mm a">{event.End}</Moment>
              </div>
          }
        </div>
        <h3 role="title" style={{ margin: '.25rem 0 .5rem 0' }}>{event.Name}</h3>
        {
          event.Description &&
          <ReactMarkdown source={event.Description} />
        }
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>
        <Button external={external} to={url}>{label}</Button>
      </div>
    </div>
  )
}

export default EventListItem